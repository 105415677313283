var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[_c('ModalRoot'),_c('div',{staticClass:"kt-header-mobile kt-header-mobile--fixed",attrs:{"id":"kt_header_mobile"}},[_c('div',{staticClass:"kt-header-mobile__logo h3"},[_c('a',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.titleName)+" ")])]),_vm._m(0)]),_c('div',{staticClass:"kt-grid kt-grid--hor kt-grid--root"},[_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"},[_c('div',{staticClass:"kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"},[(_vm.impersonate)?_c('nav',{staticClass:"navbar sticky-top container-fullwidth navbar-dark bg-dark"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_vm._v("Impersonate As: "+_vm._s(_vm.impersonate.login))]),_c('label',{staticClass:"col-6 kt-font-sm mb-0 text-right text-light",on:{"click":function($event){return _vm.logoutAs()}}},[_vm._v(" Exit Impersonate "),_c('i',{staticClass:"fas fa-sign-out-alt"})])]):_vm._e(),_vm._m(1),_c('div',{staticClass:"kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop",attrs:{"id":"kt_aside"}},[_c('div',{staticClass:"kt-aside__brand kt-grid__item",attrs:{"id":"kt_aside_brand"}},[_c('div',{staticClass:"kt-aside__brand-logo"},[_c('a',{staticClass:"text-white h4",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.titleName))])]),_c('div',{staticClass:"kt-aside__brand-tools"},[_c('button',{staticClass:"kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left",attrs:{"id":"kt_aside_toggler"},on:{"click":_vm.clickToggle}},[_c('span')])])]),_c('div',{staticClass:"kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid",attrs:{"id":"kt_aside_menu_wrapper"}},[_c('div',{staticClass:"ui-sortable kt-aside-menu",style:(_vm.scrollHeight),attrs:{"id":"kt_aside_menu","data-scroll":"true"}},[(_vm.localStorageReady)?_c('ul',{staticClass:"kt-menu__nav"},[_vm._l((_vm.menuTree),function(section,i){return [(
                                            section.isSection &&
                                            section.show
                                        )?[_c('li',{key:'m' + i,staticClass:"kt-menu__section kt-menu__section--first"},[_c('h4',{staticClass:"kt-menu__section-text"},[_vm._v(" "+_vm._s(section.name)+" ")]),_c('i',{staticClass:"kt-menu__section-icon flaticon-more-v2"})]),_vm._l((section.menu),function(menu,j){return [(
                                                    !menu.hasChild &&
                                                    menu.show
                                                )?[(!menu.hasCount)?_c('router-link',{staticClass:"kt-menu__item",attrs:{"tag":"li","aria-haspopup":"true","to":{
                                                        name: menu.route,
                                                    }}},[_c('a',{staticClass:"kt-menu__link"},[_c('i',{class:'kt-menu__link-icon ' +
                                                                menu.icon}),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(menu.name)+" "),(menu.version)?_c('sub',{staticStyle:{"padding-left":"5px","bottom":"-5px"}},[_vm._v("V"+_vm._s(menu.version))]):_vm._e()])])]):_vm._e(),(
                                                        menu.hasCount &&
                                                        _vm.isLoginChat
                                                    )?_c('router-link',{staticClass:"kt-menu__item",attrs:{"tag":"li","aria-haspopup":"true","to":{
                                                        name: menu.route,
                                                    }}},[_c('a',{staticClass:"kt-menu__link"},[_c('i',{class:'kt-menu__link-icon ' +
                                                                menu.icon}),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(menu.name)+" "),(menu.version)?_c('sub',{staticStyle:{"padding-left":"5px","bottom":"-5px"}},[_vm._v("V"+_vm._s(menu.version))]):_vm._e()]),_c('div',{staticClass:"text-right"},[(
                                                                    _vm.totalUnread >
                                                                    0
                                                                )?_c('span',{staticClass:"kt-badge kt-badge--inline kt-badge--danger kt-badge--pill max-content"},[_vm._v(" "+_vm._s(_vm.totalUnread > 999 ? "999+" : _vm.totalUnread)+" ")]):_vm._e()])])]):_vm._e()]:(
                                                    menu.hasChild &&
                                                    menu.show
                                                )?[_c('li',{staticClass:"kt-menu__item kt-menu__item--submenu kt-menu__item--here kt-menu__item",attrs:{"aria-haspopup":"true","data-ktmenu-submenu-toggle":"hover"}},[_c('a',{staticClass:"kt-menu__link kt-menu__toggle",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"kt-menu__link-icon",class:menu.icon}),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(menu.name)+" "),(menu.version)?_c('sub',{staticStyle:{"padding-left":"5px","bottom":"-5px"}},[_vm._v("V"+_vm._s(menu.version))]):_vm._e()]),_c('i',{staticClass:"kt-menu__ver-arrow la la-angle-right"})]),_c('div',{staticClass:"kt-menu__submenu"},[_c('span',{staticClass:"kt-menu__arrow"}),_c('ul',{staticClass:"kt-menu__subnav"},[_vm._l((menu.child),function(child,x){return [(
                                                                        child.show
                                                                    )?[_c('router-link',{key:'c' +
                                                                            x,staticClass:"kt-menu__item",attrs:{"tag":"li","aria-haspopup":"true","to":{
                                                                            name: child.route,
                                                                        }}},[_c('a',{staticClass:"kt-menu__link"},[_c('i',{staticClass:"kt-menu__link-bullet kt-menu__link-bullet--dot"},[_c('span')]),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(child.name)+" "),(child.version)?_c('sub',{staticStyle:{"padding-left":"5px","bottom":"-5px"}},[_vm._v("V"+_vm._s(child.version))]):_vm._e()])])])]:_vm._e()]})],2)])])]:_vm._e()]})]:_vm._e()]})],2):_vm._e()])])]),_c('div',{staticClass:"panel panel-default w-100 panel-bg-default"},[_c('div',{staticClass:"panel-body"},[(_vm.isDevelopment == 'development')?_c('div',{staticClass:"row mx-2"},[_vm._m(2)]):_vm._e(),_c('router-view')],1),_c('div',{staticClass:"kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"},[_c('div',{staticClass:"kt-footer__copyright"},[_c('a',{staticClass:"kt-link",attrs:{"href":"https://mandayahospitalgroup.com/","target":"_blank"}},[_vm._v(_vm._s(_vm.yearNow)+"  © Mandaya Hospital")])])])])])])]),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-header-mobile__toolbar"},[_c('button',{staticClass:"kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left",attrs:{"id":"kt_aside_mobile_toggler"}},[_c('span')]),_c('button',{staticClass:"kt-header-mobile__toolbar-topbar-toggler",attrs:{"id":"kt_header_mobile_topbar_toggler"}},[_c('i',{staticClass:"flaticon-more"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"kt-aside-close",attrs:{"id":"kt_aside_close_btn"}},[_c('i',{staticClass:"la la-close"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12",staticStyle:{"background-color":"yellow","padding-top":"7px"}},[_c('h4',{staticClass:"text-center kt-font-danger"},[_vm._v(" DEMO - DEMO - DEMO - DEMO ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-scrolltop",attrs:{"id":"kt_scrolltop"}},[_c('i',{staticClass:"la la-arrow-up"})])
}]

export { render, staticRenderFns }