<template>
    <div class="h-100">
        <ModalRoot></ModalRoot>
        <div id="kt_header_mobile" class="kt-header-mobile kt-header-mobile--fixed " >
         <div class="kt-header-mobile__logo h3">
            <a class="text-white">
               {{titleName}}
            </a>
         </div>
         <div class="kt-header-mobile__toolbar">
            <button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
            <button class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
         </div>
      </div>
        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div
                class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
            >
                <div
                    class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
                >
                    <nav
                        class="navbar sticky-top container-fullwidth navbar-dark bg-dark"
                        v-if="impersonate"
                    >
                        <a class="navbar-brand" href="#"
                            >Impersonate As: {{ impersonate.login }}</a
                        >
                        <!-- <h5>Impersonate As: {{impersonate.login}}</h5> -->
                        <label
                            class="col-6 kt-font-sm mb-0 text-right text-light"
                            @click="logoutAs()"
                        >
                            Exit Impersonate
                            <i class="fas fa-sign-out-alt"></i>
                        </label>
                    </nav>

                    <!-- <button id="feedback-form" >Feedback</button> -->

                    <button class="kt-aside-close" id="kt_aside_close_btn">
                        <i class="la la-close"></i>
                    </button>

                    <div
                        class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
                        id="kt_aside"
                    >
                        <div
                            class="kt-aside__brand kt-grid__item"
                            id="kt_aside_brand"
                        >
                            <div class="kt-aside__brand-logo">
                                <a href="#" class="text-white h4">{{
                                    titleName
                                }}</a>
                            </div>
                            <div class="kt-aside__brand-tools">
                                <button
                                    class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
                                    id="kt_aside_toggler"
                                    @click="clickToggle"
                                >
                                    <span></span>
                                </button>
                            </div>
                        </div>
                        <div
                            class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                            id="kt_aside_menu_wrapper"
                        >
                            <div
                                id="kt_aside_menu"
                                class="ui-sortable kt-aside-menu"
                                data-scroll="true"
                                :style="scrollHeight"
                            >
                                <ul
                                    class="kt-menu__nav"
                                    v-if="localStorageReady"
                                >
                                    <template v-for="(section, i) in menuTree">
                                        <template
                                            v-if="
                                                section.isSection &&
                                                section.show
                                            "
                                        >
                                            <li
                                                class="kt-menu__section kt-menu__section--first"
                                                :key="'m' + i"
                                            >
                                                <h4
                                                    class="kt-menu__section-text"
                                                >
                                                    {{ section.name }}
                                                </h4>
                                                <i
                                                    class="kt-menu__section-icon flaticon-more-v2"
                                                ></i>
                                            </li>
                                            <template
                                                v-for="(
                                                    menu, j
                                                ) in section.menu"
                                            >
                                                <template
                                                    v-if="
                                                        !menu.hasChild &&
                                                        menu.show
                                                    "
                                                >
                                                    <router-link
                                                        tag="li"
                                                        class="kt-menu__item"
                                                        aria-haspopup="true"
                                                        :to="{
                                                            name: menu.route,
                                                        }"
                                                        v-if="!menu.hasCount"
                                                    >
                                                        <a
                                                            class="kt-menu__link"
                                                        >
                                                            <i
                                                                :class="
                                                                    'kt-menu__link-icon ' +
                                                                    menu.icon
                                                                "
                                                            ></i>
                                                            <span
                                                                class="kt-menu__link-text"
                                                                >{{
                                                                    menu.name
                                                                }}
                                                                <sub style="padding-left: 5px; bottom: -5px;" v-if="menu.version">V{{ menu.version }}</sub>
                                                                </span
                                                            >
                                                           
                                                        </a>
                                                    </router-link>
                                                    <router-link
                                                        tag="li"
                                                        class="kt-menu__item"
                                                        aria-haspopup="true"
                                                        :to="{
                                                            name: menu.route,
                                                        }"
                                                        v-if="
                                                            menu.hasCount &&
                                                            isLoginChat
                                                        "
                                                    >
                                                        <a
                                                            class="kt-menu__link"
                                                        >
                                                            <i
                                                                :class="
                                                                    'kt-menu__link-icon ' +
                                                                    menu.icon
                                                                "
                                                            ></i>
                                                            <span
                                                                class="kt-menu__link-text"
                                                                >{{
                                                                    menu.name
                                                                }}
                                                                <sub style="padding-left: 5px; bottom: -5px;" v-if="menu.version">V{{ menu.version }}</sub>
                                                                </span
                                                            >
                                                            <div
                                                                class="text-right"
                                                            >
                                                                <span
                                                                    class="kt-badge kt-badge--inline kt-badge--danger kt-badge--pill max-content"
                                                                    v-if="
                                                                        totalUnread >
                                                                        0
                                                                    "
                                                                >
                                                                    {{
                                                                        totalUnread >
                                                                        999
                                                                            ? "999+"
                                                                            : totalUnread
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </router-link>
                                                </template>
                                                <template
                                                    v-else-if="
                                                        menu.hasChild &&
                                                        menu.show
                                                    "
                                                >
                                                    <li
                                                        class="kt-menu__item kt-menu__item--submenu kt-menu__item--here kt-menu__item"
                                                        aria-haspopup="true"
                                                        data-ktmenu-submenu-toggle="hover"
                                                    >
                                                        <a
                                                            href="javascript:;"
                                                            class="kt-menu__link kt-menu__toggle"
                                                        >
                                                            <i
                                                                class="kt-menu__link-icon"
                                                                :class="
                                                                    menu.icon
                                                                "
                                                            ></i>
                                                            <span
                                                                class="kt-menu__link-text"
                                                                >{{
                                                                    menu.name
                                                                }}
                                                                <sub style="padding-left: 5px; bottom: -5px;" v-if="menu.version">V{{ menu.version }}</sub>
                                                                </span
                                                            >
                                                            <i
                                                                class="kt-menu__ver-arrow la la-angle-right"
                                                            ></i>
                                                        </a>
                                                        <div
                                                            class="kt-menu__submenu"
                                                        >
                                                            <span
                                                                class="kt-menu__arrow"
                                                            ></span>
                                                            <ul
                                                                class="kt-menu__subnav"
                                                            >
                                                                <template
                                                                    v-for="(
                                                                        child, x
                                                                    ) in menu.child"
                                                                >
                                                                    <template
                                                                        v-if="
                                                                            child.show
                                                                        "
                                                                    >
                                                                        <router-link
                                                                            tag="li"
                                                                            class="kt-menu__item"
                                                                            aria-haspopup="true"
                                                                            :to="{
                                                                                name: child.route,
                                                                            }"
                                                                            :key="
                                                                                'c' +
                                                                                x
                                                                            "
                                                                        >
                                                                            <a
                                                                                class="kt-menu__link"
                                                                            >
                                                                                <i
                                                                                    class="kt-menu__link-bullet kt-menu__link-bullet--dot"
                                                                                >
                                                                                    <span></span>
                                                                                </i>
                                                                                <span
                                                                                    class="kt-menu__link-text"
                                                                                    >{{
                                                                                        child.name
                                                                                    }}
                                                                                    <sub style="padding-left: 5px; bottom: -5px;" v-if="child.version">V{{ child.version }}</sub>
                                                                                </span>
                                                                            </a>
                                                                        </router-link>
                                                                    </template>
                                                                </template>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="panel panel-default w-100 panel-bg-default">
                        <div class="panel-body">
                            <div
                                class="row mx-2"
                                v-if="isDevelopment == 'development'"
                            >
                                <div
                                    class="col-12"
                                    style="
                                        background-color: yellow;
                                        padding-top: 7px;
                                    "
                                >
                                    <h4 class="text-center kt-font-danger">
                                        DEMO - DEMO - DEMO - DEMO
                                    </h4>
                                </div>
                            </div>
                            <router-view></router-view>
                        </div>
                        <div
                            class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
                        >
                            <div class="kt-footer__copyright">
                                <a
                                    href="https://mandayahospitalgroup.com/"
                                    target="_blank"
                                    class="kt-link"
                                    >{{ yearNow }} &nbsp;&copy;&nbsp;Mandaya
                                    Hospital</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="kt_scrolltop" class="kt-scrolltop">
            <i class="la la-arrow-up"></i>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./services/checkPermission.service";
const checkPermission = new CheckPermission();
import { mapState } from "vuex";
import { isNullOrUndefined } from "util";
import axios from "axios";
import Vue from "vue";
import SweetAlertTemplate from "./components/_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./components/_js/BlockUI";
const blockUI = new BlockUI();
import store from "./store";
import ModalRoot from "./components/_modal/ModalRoot.vue";

export default {
    components: {
        ModalRoot,
    },
    computed: {
        ...mapState("ChatStore", {
            totalUnread: (state) => state.totalUnread,
            isLoginChat: (state) => state.isLoginChat,
        }),
        impersonate() {
            let obj = JSON.parse(localStorage.getItem("impersonate"));
            if (obj) {
                this.showLoginAsBar = true;
            }
            return obj;
        },
    },
    data() {
        return {
            titleName: "Mandaya",
            yearNow: new Date().getFullYear(),
            showLoginAsBar: false,
            isDevelopment: process.env.VUE_APP_DEVELOPMENT,
            localStorageReady: false,
            scrollHeight: null,
            menuTree: [
                {
                    name: "RAQ",
                    isSection: true,
                    hasChild: true,
                    show: false,
                    menu: [
                        {
                            accessMethod: "BackOffice.View.Menu.Dashboard",
                            name: this.$i18n.t("module.dashboard"),
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-chart-bar",
                            show: false,
                            route: "dashboard",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.CallCenter",
                            name: "Booking List",
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-book-reader",
                            show: false,
                            route: "call-center-appointment",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.RoomManagement",
                            name: "Room Management",
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-user-md",
                            show: false,
                            route: "room-management",
                        },
                        {
                            accessMethod:
                                "BackOffice.View.Menu.OutPatientDokter",
                            name: "Change Doctor",
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-user-md",
                            show: false,
                            route: "outpatient-dokter",
                        },
                        // {
                        //   accessMethod: "BackOffice.View.Menu.Reservasi",
                        //   name: this.$i18n.t('module.reservation'),
                        //   isParent: true,
                        //   hasChild: false,
                        //   icon: "fa fa-calendar-alt",
                        //   show: false,
                        //   route: "reservasi"
                        // },
                        {
                            accessMethod:
                                "BackOffice.View.Menu.DoctorAppointment",
                            name: "Doctor Appointment",
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-calendar-alt",
                            show: false,
                            route: "doctor-appointment",
                            version: "2"
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Caller",
                            name: "Caller",
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-calendar-alt",
                            show: false,
                            route: "caller",
                            version: "2"
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.RekamMedik",
                            name: this.$i18n.t("module.medicalRecord"),
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-heartbeat",
                            show: false,
                            route: "rekam-medik",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Farmasi",
                            name: this.$i18n.t("module.pharmacy"),
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-pills",
                            show: false,
                            route: "farmasi",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Antrian",
                            name: this.$i18n.t("module.queue"),
                            isParent: true,
                            hasChild: false,
                            icon: "fa fa-crown",
                            show: false,
                            route: "antrian",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Tanggapan",
                            name: "Tanggapan",
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-file-alt",
                            show: false,
                            route: "tanggapan",
                        },
                        {
                            name: "Report",
                            isParent: true,
                            hasChild: true,
                            icon: "fas fa-paste",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.TimeTrackingReservation",
                                    show: false,
                                    name: this.$i18n.t(
                                        "report.timeTrackingReservasi"
                                    ),
                                    route: "report-time-tracking-reservasi",
                                },
                            ],
                        },
                        {
                            name: "Drive Thru",
                            isParent: true,
                            hasChild: true,
                            icon: "fas fa-car-side",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.DriveThru.RunnnerFo",
                                    show: false,
                                    name: "Runner/Fo",
                                    route: "drive-thru-runner",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.DriveThru.Kasir",
                                    show: false,
                                    name: "Kasir",
                                    route: "drive-thru-booking",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.DriveThru.Lab",
                                    show: false,
                                    name: "Drive Thru Lab",
                                    route: "drive-thru-lab",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.DriveThruSwabber",
                                    show: false,
                                    name: "Drive Thru Swabber",
                                    route: "drive-thru-swabber",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "Referral",
                    isSection: true,
                    hasChild: true,
                    show: false,
                    menu: [
                        {
                            accessMethod: "BackOffice.View.Menu.Gallery",
                            name: "Gallery",
                            isParent: true,
                            hasChild: false,
                            icon: "far fa-image",
                            show: false,
                            route: "gallery",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Carousel",
                            name: "Carousel",
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-images",
                            show: false,
                            route: "carousel",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Pic",
                            name: this.$i18n.t("module.pic"),
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-user-check",
                            show: false,
                            route: "pic",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.SkemaRujukan",
                            name: this.$i18n.t("module.skemaRujukan"),
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-sitemap",
                            show: false,
                            route: "skema-rujukan",
                        },
                        {
                            accessMethod:
                                "BackOffice.View.Menu.TransaksiReferral",
                            name: this.$i18n.t("module.transaksiReferral"),
                            isParent: true,
                            hasChild: false,
                            icon: "fas fa-receipt",
                            show: false,
                            route: "transaksi-referral",
                        },
                        {
                            accessMethod: "BackOffice.View.Menu.Chat",
                            name: this.$i18n.t("module.diskusi"),
                            isParent: true,
                            hasChild: false,
                            hasCount: true,
                            icon: "fas fa-comments",
                            show: false,
                            route: "chats",
                        },
                        {
                            name: "Report",
                            isParent: true,
                            hasChild: true,
                            icon: "fas fa-paste",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.DaftarPerujuk",
                                    show: false,
                                    name: this.$i18n.t("report.daftarPerujuk"),
                                    route: "report-daftar-perujuk",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.StatusRujukan",
                                    show: false,
                                    name: this.$i18n.t("report.statusRujukan"),
                                    route: "report-status-rujukan",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.StatusReferralKeuangan",
                                    show: false,
                                    name: this.$i18n.t(
                                        "report.statusFeeKeuangan"
                                    ),
                                    route: "report-status-fee-keuangan",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.DetailTransaksi",
                                    show: false,
                                    name: this.$i18n.t(
                                        "report.detailTransaksi"
                                    ),
                                    route: "report-detail-transaksi",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Report.PendapatanReferral",
                                    show: false,
                                    name: this.$i18n.t(
                                        "report.pendapatanReferral"
                                    ),
                                    route: "report-pendapatan-referral",
                                },
                            ],
                        },
                    ],
                },
                // Loyalty
                {
                    name: "Loyalty",
                    isSection: true,
                    hasChild: true,
                    show: false,
                    menu: [
                        {
                            name: "Transaksi",
                            isParent: true,
                            hasChild: true,
                            icon: "fa fa-book",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod:
                                        "LoyaltyTransactionClaimResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.claim_point"),
                                    route: "claim-point",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyTransactionCouponResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.coupon_usages"),
                                    route: "coupon-usages",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyHistoryPointResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.history_point"),
                                    route: "history-point",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyCustomPointResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.customPoint"),
                                    route: "custom-point",
                                },
                            ],
                        },
                        {
                            name: "Data",
                            isParent: true,
                            hasChild: true,
                            icon: "fa fa-book",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod:
                                        "LoyaltyFormulaLoyaltyResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.formulaLoyalty"),
                                    route: "formula-loyalty",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyMembersResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.member"),
                                    route: "members",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyCouponResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.coupons"),
                                    route: "coupons",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "General",
                    isSection: true,
                    hasChild: true,
                    show: false,
                    menu: [
                        {
                            name: "Administrator",
                            isParent: true,
                            hasChild: true,
                            icon: "fas fa-users-cog",
                            show: false,
                            route: "",
                            child: [
                                // {
                                //     accessMethod:
                                //         "BackOffice.View.Menu.ManagementUser",
                                //     show: false,
                                //     name: this.$i18n.t("module.userManagement"),
                                //     route: "user-management"
                                // },
                                // {
                                //     accessMethod:
                                //         "BackOffice.View.Menu.GrupUser",
                                //     show: false,
                                //     name: this.$i18n.t("module.userGroup"),
                                //     route: "user-group"
                                // },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.ChatUser",
                                    show: false,
                                    name: this.$i18n.t("module.chatUser"),
                                    route: "chat-user",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.ApplicationConfiguration",
                                    show: false,
                                    name: this.$i18n.t("module.appConfig"),
                                    route: "app-config",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.SmsTemplate",
                                    show: false,
                                    name: this.$i18n.t("module.smsTemplate"),
                                    route: "sms-template",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.SmsHistory",
                                    show: false,
                                    name: this.$i18n.t("module.smsHistory"),
                                    route: "sms-history",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.EmailHistory",
                                    show: false,
                                    name: 'Email History',
                                    route: "email-history"
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.FCMHistory",
                                    show: false,
                                    name: 'FCM history',
                                    route: "fcm-history"
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.WhatsappHistory",
                                    show: false,
                                    name: 'Whatsapp History',
                                    route: "whatsapp-history"
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Elastic",
                                    show: false,
                                    name: this.$i18n.t("module.elastic"),
                                    route: "elastic",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.EntityDataVersion",
                                    show: false,
                                    name: "Entity Data Version",
                                    route: "entity-data-version",
                                },
                            ],
                        },
                        {
                            name: "Master",
                            isParent: true,
                            hasChild: true,
                            icon: "fa fa-book",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod: "BackOffice.View.Menu.Dokter",
                                    show: false,
                                    name: this.$i18n.t("module.doctor"),
                                    route: "dokter",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Doctor",
                                    show: false,
                                    name: "Dokter",
                                    route: "dokter-new",
                                    version: "2"
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Absence",
                                    show: false,
                                    name: "Ketidakhadiran Dokter",
                                    route: "ketidakhadiran",
                                    version: "2"
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Holiday",
                                    show: false,
                                    name: "Hari Libur",
                                    route: "holiday-set",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Klinik",
                                    show: false,
                                    name: "Klinik",
                                    route: "klinik",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Spesialisasi",
                                    show: false,
                                    name: "Spesialisasi",
                                    route: "master-spesialisasi",
                                },
                                // {
                                //     accessMethod: "BackOffice.View.Menu.Subspesialisasi",
                                //     show: false,
                                //     name: "Subspesialisasi",
                                //     route: "master-subspesialisasi"
                                // },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Tarif-new",
                                    show: false,
                                    name: "Tarif",
                                    route: "master-tarif",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.PicFarmasi",
                                    show: false,
                                    name: this.$i18n.t("module.picOfPharmacy"),
                                    route: "pic-farmasi",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Pasien",
                                    show: false,
                                    name: this.$i18n.t("module.patient"),
                                    route: "pasien",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Unit",
                                    show: false,
                                    name: this.$i18n.t("module.unit"),
                                    route: "unit",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.UnitAntrian",
                                    show: false,
                                    name: this.$i18n.t("module.queueUnit"),
                                    route: "unit-antrian",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.TujuanRujukan",
                                    show: false,
                                    name: this.$i18n.t("module.tujuanRujukan"),
                                    route: "tujuan-rujukan",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.JenisPenjamin",
                                    show: false,
                                    name: this.$i18n.t("module.jenisPenjamin"),
                                    route: "jenis-penjamin",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Perujuk",
                                    show: false,
                                    name: this.$i18n.t("module.perujuk"),
                                    route: "perujuk",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Tugas",
                                    show: false,
                                    name: this.$i18n.t("module.tugas"),
                                    route: "tugas",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Kategori",
                                    show: false,
                                    name: this.$i18n.t("module.kategori"),
                                    route: "kategori",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Kelas",
                                    show: false,
                                    name: this.$i18n.t("module.kelas"),
                                    route: "kelas",
                                },
                                {
                                    accessMethod:
                                        "BackOffice.View.Menu.Tindakan",
                                    show: false,
                                    name: this.$i18n.t("module.tindakan"),
                                    route: "tindakan",
                                },
                                {
                                    accessMethod: "BackOffice.View.Menu.Tarif",
                                    show: false,
                                    name: this.$i18n.t("module.tarif"),
                                    route: "tarif",
                                },
                                // {
                                //   accessMethod: "ReservasiResource.GET.Reservasi",
                                //   name: "Chat User",
                                //   route: "#"
                                // },
                                {
                                    accessMethod:
                                        "LoyaltyLoyaltyTierResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t("module.loyaltyTier"),
                                    route: "loyalty-tier",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyTransactionPointTypeResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t(
                                        "module.transactionPointType"
                                    ),
                                    route: "transaction-point-type",
                                },
                                {
                                    accessMethod:
                                        "LoyaltyPromotionCategoriesResource.GET.menu",
                                    show: false,
                                    name: this.$i18n.t(
                                        "module.promotionCategories"
                                    ),
                                    route: "kategori-promo",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "E-Commerce",
                    isSection: true,
                    hasChild: true,
                    show: false,
                    menu: [
                        {
                            name: "Manajemen Pembayaran",
                            isParent: true,
                            hasChild: true,
                            icon: "fas fa-money-check-alt",
                            show: false,
                            route: "",
                            child: [
                                {
                                    accessMethod: "BackOffice.View.Menu.EcommercePayment",
                                    show: false,
                                    name: 'Pengaturan Klien',
                                    route: "ManajementPayment"
                                },
                            {
                                    accessMethod: "BackOffice.View.Menu.EcommercePayment",
                                    show: false,
                                    name: 'Pengaturan Global',
                                    route: "payment-global"
                                }
                            ]
                        },
                        {
                        accessMethod: "BackOffice.View.Menu.EcommerceNotification",
                        name: "Manajemen Notifikasi",
                        isParent: true,
                        hasChild: false,
                        icon: "fas fa-bell",
                        show: false,
                        route: "ManajementNotifikasi"
                        },
                        {
                        accessMethod: "BackOffice.View.Menu.EcommerceSendNotification",
                        name: "Send Notifikasi",
                        isParent: true,
                        hasChild: false,
                        icon: "fas fa-paper-plane",
                        show: false,
                        route: "send-notifikasi"
                        }
                    ]
                }
            ],
        };
    },
    methods: {
        checkPermission: function (method, menuShow) {
            const validPermission = checkPermission.isCanAccess(method);
            // if(validPermission) {
            //   if(!isNullOrUndefined(menuShow)) {
            //     menuShow = true;
            //   }
            // }
            return validPermission;
        },
        clickToggle() {
            // this.$eventBus.$emit("toggle-click");
        },
        logoutAs() {
            localStorage.removeItem("new_token");
            localStorage.removeItem("new_refresh");
            localStorage.removeItem("impersonate");
            location.reload();
            this.$router.go(1);
        },
        checkWindowSize: function () {
            var totalHeight = parseInt($("#kt_aside").height()) - 115;
            this.scrollHeight = "height:" + totalHeight + "px;";
        },
        initialMenu: function () {
            this.menuTree.forEach((value, i) => {
                if (value.hasChild) {
                    value.menu.forEach((menu, j) => {
                        if (menu.hasChild) {
                            menu.child.forEach((child, x) => {
                                const isHaveAccess = this.checkPermission(
                                    child.accessMethod
                                );
                                if (isHaveAccess) {
                                    child.show = true;
                                    menu.show = true;
                                    value.show = true;
                                }
                            });
                        } else {
                            const isHaveAccess = this.checkPermission(
                                menu.accessMethod
                            );
                            if (isHaveAccess) {
                                menu.show = true;
                                value.show = true;
                            }
                        }
                    });
                }
            });
        },
        initChat: function () {
            var vx = this;
            if (this.checkPermission("BackOffice.View.Menu.Chat")) {
                if (window.is_rck_chat_login) {
                    store.dispatch("ChatStore/INIT_CHAT", rtrc);
                    store.dispatch("ChatStore/SET_LOGIN_CHAT");
                } else {
                    blockUI.blockPage();
                    let instance = new SweetAlert().showConfirm({
                        swalTitle: "Fitur chat Anda sedang mengalami masalah",
                        swalText:
                            "Menu diskusi akan dihilangkan atau silahkan logout dan login kembali",
                        swalType: "warning",
                        textConfirm: "Logout",
                        textCancel: "Close",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                            vx.doLogOut();
                        },
                        onCancelButton: function () {
                            blockUI.unblockPage();
                        },
                    });
                }
            } else {
                window.is_rck_chat_login = false;
            }
        },
        doLogOut: function () {
            localStorage.removeItem("permission");
            window.location.replace(window.location.origin + "/logout");
        },
    },
    created() {
        // console.log(refresh_token, 'baru');
        // console.log(localStorage.getItem('new_token'), 'baru');
        if (this.impersonate) {
            window.access_token = localStorage.getItem("new_token");
            window.refresh_token = localStorage.getItem("new_refresh");
            window.user_name = this.impersonate.login;
        }
        var vx = this;
        // new Promise((resolve, reject) => {
        // })
        setTimeout(() => {
            checkPermission
                .updatePermission()
                .then((response) => {
                    vx.initialMenu();
                    vx.localStorageReady = true;
                    // vx.initChat();
                })
                .catch((error) => {
                    console.log("error dari created");
                    return error;
                });
        }, 500);
    },
    mounted() {
        const origin = window.location.origin;
        sessionStorage.removeItem("viewCaller");
        let listName = localStorage.idpHint;
        let selectedTenant = "";
        try {
            const parseToken = JSON.parse(
                atob(localStorage.tokens.split(".")[1])
            );
            selectedTenant = parseToken.tenant_id;
            listName = JSON.parse(listName);
            const selectedObj = listName.find(
                (_item) => _item.tenant_id == selectedTenant
            );
            this.titleName = selectedObj ? selectedObj.displayName : "Mandaya";
        } catch {
            //
        }
        var script = document.createElement("script");
        script.src = `${origin}/vendors/demo2/base/scripts.bundle.js`;
        document.getElementsByTagName("head")[0].appendChild(script);
        let hrefCss = [
            `${origin}/vendors/demo2/base/style.bundle.css`,
            `${origin}/vendors/demo2/skins/aside/brand.css`,
        ];
        for (let i = 0; i < hrefCss.length; i++) {
            var link = document.createElement("link");
            link.setAttribute("rel", "stylesheet");
            link.href = hrefCss[i];
            document.getElementsByTagName("head")[0].appendChild(link);
        }

        // if (document.getElementById("zammad_form_script")) return;
        // var scriptTag = document.createElement("script");
        // scriptTag.src =
        //     "https://ticketing.mandayamedical.group/assets/form/form.js";
        // scriptTag.id = "zammad_form_script";
        // document.getElementsByTagName("head")[0].appendChild(scriptTag);

        // setTimeout(() => {
        //     $(function() {
        //         $("#feedback-form").ZammadForm({
        //             messageTitle: `Internal Feedback-[Env Prod/Sim/Dev]-'TEST' - ${moment(
        //                 new Date()
        //             ).format("YYYY/MM/DD")}`,
        //             messageSubmit: "Submit",
        //             messageThankYou:
        //                 "Terima kasih atas feedbacknya (Nomor tiket#%s)! Kami akan segera merespon sesegera mungkin.",
        //             showTitle: true,
        //             modal: true,
        //             attachmentSupport: true
        //         });
        //     });
        // }, 500);

        var vx = this;
        this.checkWindowSize();
        $(window).resize(function () {
            vx.checkWindowSize();
        });
    },
};
</script>
